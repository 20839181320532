import {
    db,
    GET,
    firebase
} from '@/firebase.js'
import CompCard from '@/components/CompCard/CompCard.vue'
import Authblocker from '@/components/Authblocker/Authblocker.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import CellTooltip from '@/components/CellTooltip/CellTooltip.vue'
import ranker from '@/assets/js/ranker.js'

export default {
    name: 'Ranking',
    components: {
        CompCard,
        Authblocker,
        Tabs,
        Skeleton,
        CellTooltip
    },
    data: function () {
        return {
            orgs: [],
            title: null,
            desc: null,
            unit: null,
            rid: null,
            tabs: ['Current', 'Trends'],
            activeTab: 'Current',
            orderBy: '2020',
            queryBusy: false,
            allOrgsFetched: false,
            lastVisible: null,
            lastOrderBy: null,
            icon: null,
            color: null,
            id: null,
            trends: false,
            lastFetchedOrgs: null
        }
    },
    methods: {
        isNanOrUndefined: function (val) {
            return val === undefined || isNaN(val)
        },
        nanSort: function (a, b) {
            if (this.isNanOrUndefined(a.value.current) && !this.isNanOrUndefined(b.value.current)) return 1
            else if (!this.isNanOrUndefined(a.value.current) && this.isNanOrUndefined(b.value.current)) return -1
            else return 0
        },
        nanFilter: function (org) {
            let objectKey = this.activeTab.toLowerCase()
            if (objectKey === 'trends') objectKey = 'trend'
            return this.isNanOrUndefined(org.value[objectKey])
        },
        notNanFilter: function (org) {
            let objectKey = this.activeTab.toLowerCase()
            if (objectKey === 'trends') objectKey = 'trend'
            return org.value[objectKey] !== undefined && !isNaN(org.value[objectKey])
        },
        getCellTooltipData: function () {
            return {
                title: this.activeTab === 'Trends' ? 'Trend since 2017' : 'Closest to 50/50',
                desc: this.activeTab === 'Trends' ?
                    'Sorted based on improvement' : 'Sorted based on current data.'
            }
        },
        showMoreContent: function () {
            this.getOrgs(true)
        },
        switchTab: function (tab) {
            if (this.queryBusy && !this.allOrgsFetched) return
            this.activeTab = tab
            if (tab === 'Trends') this.orderBy = 'trend'
            else this.orderBy = '2020'
            this.resetRanking()
            this.getOrgs()
            this.orgs = []
        },
        resetRanking: function () {
            this.lastVisible = null
            this.queryBusy = false
            this.allOrgsFetched = false
        },
        getOrgs: function (force = false) {
            if ((this.queryBusy || this.allOrgsFetched || !this.rid) && !force) return
            let col = ranker.getQuery({
                rid: this.rid,
                type: this.type,
                orderBy: this.orderBy,
                col: db.collection('orgs'),
                eventId: this.$route.query.eventId
            })
            if (this.rid.split('_')[1] !== 'ghg') col = col.limit(10)
            if (this.lastVisible) col = col.startAfter(this.lastVisible)
            if (!force && this.orderBy === this.lastOrderBy && (!this.$user || !this.$user.uid || !firebase.auth().currentUser) && this.orgs.length > 0) return console.log('User is not authorized')
            this.queryBusy = true
            this.lastOrderBy = this.orderBy
            GET(col, true).then(orgs => {
                if (this.lastOrderBy !== this.orderBy) return
                this.lastVisible = orgs.docs[orgs.docs.length - 1]
                this.lastFetchedOrgs = orgs.docs
                if (!this.lastVisible) {
                    this.allOrgsFetched = true
                    return console.log('All orgs fetched')
                }
                orgs.forEach(org => {
                    let value = ranker.getValues({
                        trends: this.trends,
                        org: org,
                        rid: this.rid,
                        type: this.type
                    })
                    this.orgs.push({
                        logo: org.data().logo,
                        name: org.data().name,
                        oid: org.data().oid,
                        categories: org.data().categories,
                        value: {
                            current: value.current,
                            trend: value.trend,
                            unit: this.unit,
                            incPos: this.incPos,
                            orderByTrend: (this.orderBy === 'trend')
                        }
                    })
                })
                this.orgs.loaded = true
                this.queryBusy = false
            })
        },
        getRanking: function () {
            console.log('Get ranking')
            let doc = db.collection('rankings').doc(this.$route.params.id)
            GET(doc).then(ranking => {
                this.title = ranking.data().name
                this.desc = ranking.data().desc
                this.rid = ranking.data().rid
                this.unit = ranking.data().unit
                this.incPos = ranking.data().inc_pos
                this.icon = ranking.data().icon
                this.color = ranking.data().color
                this.trends = ranking.data().trends
                this.type = ranking.data().type
                this.getOrgs()
            })
        },
        resetData: function () {
            this.title = null
            this.desc = null
            this.rid = null
            this.unit = null
            this.incPos = null
            this.icon = null
            this.color = null
            this.orderBy = '2020'
            this.activeTab = 'Current'
            this.orgs = []
        }
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id && (!this.title || this.id !== this.$route.params.id) && this.$route.name === 'Ranking') {
                this.resetData()
                this.resetRanking()
                this.getRanking(this.$route.params.id)
                window.scrollTo(0, 0)
            }
            if (this.$route.name === 'Ranking') this.id = this.$route.params.id
        },
    },
    created: function () {
        if (this.$route.params.id) this.getRanking(this.$route.params.id)
        this.id = this.$route.params.id
    },
}